import { IAction, ITemplate } from '../../schemas';
import {
	ADD_TEMPLATE,
	DELETE_TEMPLATE,
	GET_TEMPLATE_LIST,
	UPDATE_TEMPLATE,
} from '../constant';

const initState: ITemplate[] = [];

export default function templatesReducer(
	preState = initState,
	action: IAction<any>
): ITemplate[] {
	const { type, data } = action;
	switch (type) {
		case GET_TEMPLATE_LIST:
			return [...data];
		case ADD_TEMPLATE:
			return [...data, ...preState];
		case UPDATE_TEMPLATE:
			const index = preState.findIndex((item) => item.id === action.data.id);
			const updateData = [
				...preState.slice(0, index),
				{
					...data,
				},
				...preState.slice(index + 1),
			];
			return updateData;
		case DELETE_TEMPLATE:
			return preState.filter((d) => d.id !== action.data.id);
		default:
			return preState;
	}
}
