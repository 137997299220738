export const SHARE_NAV_SWITCH = 'share_nav_switch';

export const GET_CARD_LIST = 'get_card_list';

export const GET_TEMPLATE_LIST = 'get_template_list';
export const ADD_TEMPLATE = 'add_template';
export const UPDATE_TEMPLATE = 'update_template';
export const DELETE_TEMPLATE = 'delete_template';

export const GET_TEAMUSER_LIST = 'get_teamuser_list';

export const GET_ADMINUSER_LIST = 'get_adminuser_list';
export const ADD_ADMINUSER = 'add_adminuser';
export const DELETE_ADMINUSER = 'delete_adminuser';

export const GET_COUNTRY_LIST = 'get_country_list';
export const ADD_COUNTRY = 'add_country';
export const DELETE_COUNTRY = 'delete_country';
