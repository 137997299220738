import { IAction, ITeamUser } from '../../schemas';
import { GET_TEAMUSER_LIST } from '../constant';

const initState: ITeamUser[] = [];

export default function teamusersReducer(
	preState = initState,
	action: IAction<ITeamUser[]>
): ITeamUser[] {
	const { type, data } = action;
	switch (type) {
		case GET_TEAMUSER_LIST:
			return [...data];
		default:
			return preState;
	}
}
