import { IAction, ICard } from '../../schemas';
import { GET_CARD_LIST } from '../constant';

const initState: any = {};

export default function cardsReducer(
	preState = initState,
	action: IAction<any>
): any {
	const { type, data } = action;
	switch (type) {
		case GET_CARD_LIST:
			return { ...data };
		default:
			return preState;
	}
}
