import { combineReducers } from 'redux';
import cards from './cards';
import templates from './templates';
import teamusers from './teamusers';
import adminusers from './adminusers';
import countries from './countries';
import sharedPortalNavSwitch from './shared';

export default combineReducers({
	cards,
	templates,
	teamusers,
	adminusers,
	countries,
	sharedPortalNavSwitch,
});
