import React from 'react';

import crypto from 'crypto';
import * as microsoftTeams from '@microsoft/teams-js';
import * as APP_ENV from '../../app-env';

/**
 * This component is used to redirect the user to the Azure authorization endpoint from a popup
 */
class AuthStart extends React.Component {
	componentDidMount() {
		// Initialize the Microsoft Teams SDK
		microsoftTeams.initialize();

		// Get the user context in order to extract the tenant ID
		microsoftTeams.getContext((context: microsoftTeams.Context) => {
			let tenant = context['tid']; //Tenant ID of the logged in user
			let client_id = APP_ENV.AAD_APP_CLIENT_ID; //Client ID of the Azure AD app registration ( may be from different tenant for multitenant apps)

			//Form a query for the Azure implicit grant authorization flow
			//https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-implicit-grant-flow
			let queryParams: any = {
				tenant: `${tenant}`,
				client_id: `${client_id}`,
				response_type: 'id_token token', //token_id in other samples is only needed if using open ID
				response_mode: 'fragment',
				scope: 'email openid profile User.Read Mail.Send offline_access',
				redirect_uri: window.location.origin + '/auth-end',
				nonce: crypto.randomBytes(16).toString('base64'),
			};

			let url = `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?`;
			queryParams = new URLSearchParams(queryParams).toString();
			let authorizeEndpoint = url + queryParams;

			//Redirect to the Azure authorization endpoint. When that flow completes, the user will be directed to auth-end
			//Go to AuthEnd.tsx
			window.location.assign(authorizeEndpoint);
		});
	}

	render() {
		return (
			<div
				style={{
					width: '100%',
					height: '100vh',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					color: '#242424',
				}}
			>
				<h1>Redirecting to consent page...</h1>
				<br />
				<div>
					<h3>
						If the consent window does not open, please configuring your web
						browser to allow pop-up windows for Microsoft Teams.
					</h3>
				</div>
			</div>
		);
	}
}

export default AuthStart;
