import React from 'react';
import loadable from '@loadable/component';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

// https://github.com/gregberge/loadable-components/pull/226
function load(fn, options) {
	const Component = loadable(fn, options);

	Component.preload = fn.requireAsync || fn;

	return Component;
}

function LoadingComponent(props: {
	error: boolean;
	timedOut: boolean;
	pastDelay: boolean;
}) {
	if (props.error) {
		console.error(props.error);
		return null;
	}
	return (
		<div className='panel-loading'>
			<Spinner
				size={SpinnerSize.large}
				label='Loading...'
				styles={{
					label: {
						color: 'white',
						fontSize: '1rem',
						fontWeight: 'bold',
					},
				}}
			/>
		</div>
	);
}

export default (loader) =>
	load(loader, {
		fallback: LoadingComponent({
			pastDelay: true,
			error: false,
			timedOut: false,
		}),
	});
