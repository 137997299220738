import React, { Component } from 'react';
import { Provider, teamsTheme } from '@fluentui/react-northstar';
import { Route, Switch } from 'react-router';
import { CardTask, PortalTab } from './pages';
import { AuthEnd, AuthStart } from './pages/Auth';
import * as microsoftTeams from '@microsoft/teams-js';
import lazyload from './utils/lazyload';

export default class App extends Component {
	state = {
		theme: teamsTheme,
	};

	render() {
		return (
			<Provider theme={this.state.theme} lang='en-US'>
				<Switch>
					{/* <Route path='/portal' component={PortalTab} /> */}
					<Route
						path='/portal'
						component={lazyload(() => import('./pages/PortalTab'))}
					/>
					{/* <Route path='/cardtask' component={CardTask} /> */}
					<Route
						path='/cardtask'
						component={lazyload(() => import('./pages/CardTask'))}
					/>
					<Route path='/auth-start' component={AuthStart} />
					<Route path='/auth-end' component={AuthEnd} />
				</Switch>
			</Provider>
		);
	}
}
