import { IAction, IAdminUser } from '../../schemas';
import {
	ADD_ADMINUSER,
	DELETE_ADMINUSER,
	GET_ADMINUSER_LIST,
} from '../constant';

const initState: IAdminUser[] = [];

export default function adminUsersReducer(
	preState = initState,
	action: IAction<any>
): IAdminUser[] {
	const { type, data } = action;
	switch (type) {
		case GET_ADMINUSER_LIST:
			return [...data];
		case ADD_ADMINUSER:
			return [...data, ...preState];
		case DELETE_ADMINUSER:
			return preState.filter((d) => d.id !== action.data.id);
		default:
			return preState;
	}
}
